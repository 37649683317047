import Swiper from "swiper/bundle";

// import styles bundle
import "swiper/css/bundle";

jQuery(document).ready(function ($) {
    //sliders
    //header homepage swiper
    const headerSwiper = new Swiper("#headerSwiper.slider", {
        slidesPerView: 1,
        effect: "fade",
        autoplay: true,
        loop: true,
        delay: 2000,
        speed: 1000,
        on: {
            init: function () {
                $(
                    "#headerSwiper .swiper-slide[data-id=0] .headerRevealContainer"
                ).each(function (i, obj) {
                    setTimeout(function () {
                        $(obj).addClass("leftIn");
                    }, 300 * i);
                });
            },
        },
    });

    headerSwiper.on("slideChangeTransitionStart", function () {
        $("#headerSwiper .swiper-slide .headerRevealContainer").css({
            transition: "width 0.3s ease-in-out",
        });
        $("#headerSwiper .swiper-slide .headerRevealContainer").removeClass(
            "leftIn"
        );

        setTimeout(function () {
            $("#headerSwiper .swiper-slide .headerRevealContainer").css({
                transition: "width 0.5s ease-in-out",
            });
        }, 500);
    });

    headerSwiper.on("slideChangeTransitionEnd", function () {
        // myCallbackfunction(this);
        const index = this.realIndex + 1;
        const current_data = this.slides[index].dataset.id;

        $(
            "#headerSwiper .swiper-slide[data-id=" +
                current_data +
                "] .headerRevealContainer"
        ).each(function (i, obj) {
            setTimeout(function () {
                $(obj).addClass("leftIn");
            }, 300 * i);
        });
    });

    const latestSlider = document.querySelectorAll(".latestSlider-wrapper");
    var i = 0;

    for (i = 0; i < latestSlider.length; i++) {
        var first = latestSlider[i].nextElementSibling;
        var second = first.nextElementSibling;
        var third = second.nextElementSibling;

        latestSlider[i].classList.add("latestSlider-wrapper-" + i); //slider
        first.classList.add("latestSlider-pagination-" + i); //pagination
        second.classList.add("latestSlider-button-next-" + i); //next
        third.classList.add("latestSlider-button-prev-" + i); //previous

        var slider = new Swiper(".latestSlider-wrapper-" + i, {
            speed: 1000,
            slidesPerView: 3,
            spaceBetween: 70,
            direction: "horizontal",
            loop: true,
            autoplay: false,
            pagination: {
                el: ".latestSlider-pagination-" + i,
                type: "bullets",
            },
            navigation: {
                nextEl: ".latestSlider-button-next-" + i,
                prevEl: ".latestSlider-button-prev-" + i,
            },
            breakpoints: {
                991: {
                    slidesPerView: 3,
                },
                576: {
                    slidesPerView: 2,
                },
                400: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                },
                300: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                },
            },
        });
    }

    const awardsSlider = document.querySelectorAll(".awardsSlider-wrapper");
    var i = 0;

    for (i = 0; i < awardsSlider.length; i++) {
        var first = awardsSlider[i].nextElementSibling;
        var second = first.nextElementSibling;
        var third = second.nextElementSibling;

        awardsSlider[i].classList.add("awardsSlider-wrapper-" + i); //slider
        first.classList.add("swiper-pagination-" + i); //pagination
        second.classList.add("awardsSlider-button-next-" + i); //next
        third.classList.add("awardsSlider-button-prev-" + i); //previous

        var slider = new Swiper(".awardsSlider-wrapper-" + i, {
            speed: 1000,
            slidesPerView: 1,
            spaceBetween: 30,
            direction: "horizontal",
            loop: true,
            autoplay: false,

            navigation: {
                nextEl: ".awardsSlider-button-next-" + i,
                prevEl: ".awardsSlider-button-prev-" + i,
            },
        });
    }

    const iconsSlider = document.querySelectorAll(".iconsSlider-wrapper");

    for (i = 0; i < iconsSlider.length; i++) {
        var spacing = 110;

        if (iconsSlider[i].hasAttribute("data-perview")) {
            var perView = iconsSlider[i].dataset.perview;
        } else {
            var perView = 6;
        }

        iconsSlider[i].classList.add("iconsSlider-wrapper-" + i); //slider

        var slider = new Swiper(".iconsSlider-wrapper-" + i, {
            speed: 1000,
            // slidesPerView: perView,
            spaceBetween: spacing,
            direction: "horizontal",
            loop: true,
            autoplay: {
                delay: 0,
            },
            breakpoints: {
                // when window width is >= 320px
                1460: {
                    slidesPerView: perView,
                },
                1200: {
                    slidesPerView: 5,
                },
                991: {
                    slidesPerView: 4,
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                300: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
            },
        });
    }

    const testimonialSlider = document.querySelectorAll(
        ".testimonialSlider-wrapper"
    );
    var i = 0;

    for (i = 0; i < testimonialSlider.length; i++) {
        var first = testimonialSlider[i].nextElementSibling;
        var second = first.nextElementSibling;
        var third = second.nextElementSibling;

        testimonialSlider[i].classList.add("testimonialSlider-wrapper-" + i); //slider
        first.classList.add("swiper-pagination-" + i); //pagination
        second.classList.add("testimonialSlider-button-next-" + i); //next
        third.classList.add("testimonialSlider-button-prev-" + i); //previous

        var slider = new Swiper(".testimonialSlider-wrapper-" + i, {
            speed: 1000,
            slidesPerView: 1,
            spaceBetween: 30,
            direction: "horizontal",
            loop: true,
            autoplay: false,
            navigation: {
                nextEl: ".testimonialSlider-button-next-" + i,
                prevEl: ".testimonialSlider-button-prev-" + i,
            },
        });
    }

    const storySlider = document.querySelectorAll(".storySlider-wrapper");
    var i = 0;

    for (i = 0; i < storySlider.length; i++) {
        storySlider[i].classList.add("storySlider-wrapper-" + i); //slider

        var storySliderFades = new Swiper(".storySlider-wrapper-" + i, {
            speed: 1000,
            effect: "fade",
            allowTouchMove: false,
            fadeEffect: {
                crossFade: true,
            },
            slidesPerView: "auto",
            spaceBetween: 30,
            direction: "horizontal",
            autoplay: false,
            loop: true,
            navigation: {
                nextEl: ".storySlider-button-next-" + i,
                prevEl: ".storySlider-button-prev-" + i,
            },
        });
    }

    const storySlider2 = document.querySelectorAll(
        ".storySlider-wrapper-second"
    );
    var i = 0;

    for (i = 0; i < storySlider2.length; i++) {
        var first = storySlider2[i].nextElementSibling;
        var second = first.nextElementSibling;
        var third = second.nextElementSibling;

        storySlider2[i].classList.add("storySlider-wrapper-second-" + i); //slider
        first.classList.add("storySlider-pagination-" + i); //pagination
        second.classList.add("storySlider-button-next-" + i); //next
        third.classList.add("storySlider-button-prev-" + i); //previous

        var storySliderSlides = new Swiper(".storySlider-wrapper-second-" + i, {
            speed: 1000,
            spaceBetween: 30,
            direction: "horizontal",
            autoplay: false,
            loop: true,
            slidesPerView: "auto",
            pagination: {
                el: ".storySlider-pagination-" + i,
                type: "bullets",
            },
            navigation: {
                nextEl: ".storySlider-button-next-" + i,
                prevEl: ".storySlider-button-prev-" + i,
            },
        });

        //storySliderSlides.controller.control = storySliderFades;

        storySliderSlides.on("slideChange", function () {
            const index = this.realIndex + 1;
            storySliderFades.slideTo(index);
        });

        $("body").on(
            "click",
            ".storySlider-button-next-" + i,
            function (event) {
                storySliderFades.slideNext();
            }
        );

        $("body").on(
            "click",
            ".storySlider-button-prev-" + i,
            function (event) {
                storySliderFades.slidePrev();
            }
        );
    }

    const resourceSlider = document.querySelectorAll(
        ".resourceSlider-wrapper-second"
    );
    var i = 0;

    for (i = 0; i < resourceSlider.length; i++) {
        var first = resourceSlider[i].nextElementSibling;
        var second = first.nextElementSibling;
        var third = second.nextElementSibling;

        resourceSlider[i].classList.add("resourceSlider-wrapper-second-" + i); //slider
        first.classList.add("resourceSlider-pagination-" + i); //pagination
        second.classList.add("resourceSlider-button-next-" + i); //next
        third.classList.add("resourceSlider-button-prev-" + i); //previous

        var storySliderSlides = new Swiper(
            ".resourceSlider-wrapper-second-" + i,
            {
                speed: 1000,
                spaceBetween: 30,
                direction: "horizontal",
                autoplay: false,
                loop: true,
                slidesPerView: "auto",
                pagination: {
                    el: ".resourceSlider-pagination-" + i,
                    type: "bullets",
                },
                navigation: {
                    nextEl: ".resourceSlider-button-next-" + i,
                    prevEl: ".resourceSlider-button-prev-" + i,
                },
            }
        );
    }

    //menu
    $("body").on("click", ".mobileMenu", function () {
        $("#page").toggleClass("mobileOpen");
        $(".menu .content").toggleClass("opacity");
        // $('.menu .content').toggle();
        $(".menuContainer").toggleClass("active");
        $(".menuContainer .menu").toggleClass("show");

        $(this).toggleClass("open");

        if ($(".menu").hasClass("show")) {
            $("body").addClass("fixed-position");
        } else {
            $("body").removeClass("fixed-position");
        }
    });

    //menu detection to show sticky nav classes only used for svg in header
    // $(document).scroll(function () {
    //     if ($(window).scrollTop() > 0) {
    //         $("header").addClass("scrolled");
    //         var mySVG = document.getElementById("headerSVG");
    //         mySVG.setAttribute("viewBox", "0 0 188.57 32");
    //         mySVG.setAttribute("height", "32");
    //         $("#headerSVG .pr").css("display", "none");
    //     }

    //     if ($(window).scrollTop() === 0 || $(window).scrollTop() < 0) {
    //         $("header").removeClass("scrolled");
    //         var mySVG = document.getElementById("headerSVG");
    //         mySVG.setAttribute("viewBox", "0 0 188.57 51.256");
    //         mySVG.setAttribute("height", "51.256");
    //         $("#headerSVG .pr").css("display", "block");
    //     }
    // });

    // if (window.scrollY != 0 && window.scrollY > 0) {
    //     $("header").addClass("scrolled");
    //     var mySVG = document.getElementById("headerSVG");
    //     mySVG.setAttribute("viewBox", "0 0 188.57 51.256");
    // }

    // var iframe = document.getElementById("crmIframe");

    // // Adjusting the iframe height onload event
    // iframe.onload = function(){
    //     iframe.style.height = iframe.contentWindow.document.body.scrollHeight + 'px';
    // }

    autoPlayYouTubeModal();

    function autoPlayYouTubeModal() {
        var trigger = $(".openVideoModal");
        trigger.click(function () {
            var theModal = $(this).data("bs-target");
            var videoSRC = $(this).data("src");

            var videoSRCauto =
                videoSRC +
                "?autoplay=1&mute=0&color=white&controls=0&modestbranding=1&playsinline=1&rel=0&enablejsapi=1";
            $(theModal + " iframe").attr(
                "allow",
                "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            );
            $(theModal + " iframe").attr("src", videoSRCauto);

            $(theModal).on("hidden.bs.modal", function (e) {
                $(theModal + " iframe").attr("src", "");
            });
        });
    }

    // $("#sessionmodal").modal({ backdrop: "static", keyboard: false });
    // //popup session modal
    // setTimeout(() => {
    //     $("#sessionmodal").modal("show");
    // }, "3000");

    // $("body").on("click", ".session-modal", function (event) {
    //     event.preventDefault();
    //     var url = $(this).attr('href');
    //     console.log("clicked");
    //     $.ajax({
    //         type: "POST",
    //         url: site_data.adminajax,
    //         dataType: "json",
    //         data: {
    //             action: "session",
    //         },
    //     })
    //         .done(function (response) {
    //             if (response.status == "success") {
    //                 $("#sessionmodal").modal("hide");
    //                 if (url) {
    //                     window.location.href = url;
    //                 }
    //             }
    //         })
    //         .fail(function () {})
    //         .always(function () {});
    // });

    if(!document.cookie.includes('popup_shown')) {
		setTimeout(function () {
			$("#sessionmodal").modal("show");
		}, 3000);
	}
    
    let url;

	$('.btn-close.session-modal').click(function() {
		//$('#sessionmodal').stop().fadeOut();
        
        $('#sessionmodal').stop().modal("hide");

		let date = new Date();
		date.setDate(date.getDate() + 1);
		let expires = "expires=" + date.toUTCString();

		document.cookie = 'popup_shown=1;' + expires + '; path=/;';
	})

    $('.pop-up-cta').click(function() {
		//$('#sessionmodal').stop().fadeOut();
        $('#sessionmodal').stop().modal("hide");
        var url = $(this).attr('href');
		let date = new Date();
		date.setDate(date.getDate() + 1);
		let expires = "expires=" + date.toUTCString();

		document.cookie = 'popup_shown=1;' + expires + '; path=/;';

        if (url) {
            window.location.href = url;
        }
	})

});
